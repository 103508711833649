import React from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
// language configure
import { esES } from '@material-ui/core/locale';
import Routes from './routes/Routes';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0caff0', // #0caff0 #3ca273
    },
    success: {
      main: green[500],
    },
    secondary: {
      main: '#FECA19',
    },
  },
}, esES );

const App = (): JSX.Element => (
  <ThemeProvider theme={theme}>
    <Routes />
  </ThemeProvider>
);

export default App;
