import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBoEylZgFu_ezK0_7tHFZXOezVUjDQINrM',
  authDomain: 'mybook-paula.firebaseapp.com',
  projectId: 'mybook-paula',
  storageBucket: 'mybook-paula.appspot.com',
  messagingSenderId: '122505659291',
  appId: '1:122505659291:web:5120374f48137dada9d73e',
  measurementId: 'G-QF18PR9NMZ',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
